import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
    config => {
        const jwt = localStorage.getItem('JWT')
        if (jwt !== '') config.headers.Authorization = `Bearer ${jwt}`

        return config
    },
    error => Promise.reject(error)
)

axiosIns.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        store.dispatch('user/setLoginStatus', false)
        if (router.currentRoute && router.currentRoute.name && router.currentRoute.name !== 'login') {
            router.push('/login').then()
        }
    } else if (error.response.status === 403) {
        if (error.response.data === 'user no client') {
            store.dispatch('user/setUserNoClients')
        }
    }

    return Promise.reject(error)
})


Vue.prototype.$http = axiosIns

export default axiosIns
