export default [
  {
    title: 'general.home',
    route: 'home',
    icon: 'HomeIcon'
    // required_permission: ['admin']
    // če dodaš array required_permission, se bodo na levi strani v navbaru izrisovali samo routi,
    // z dovoljenimi permissioni
    // koda ki to dejansko preverja se nahaja v: 
    // src\@core\layouts\layout-vertical\components\vertical-nav-menu\components\vertical-nav-menu-items\VerticalNavMenuItems.vue
  },
  {
    title: 'general.clients',
    route: 'clients',
    icon: 'BriefcaseIcon'
  },
  {
    title: 'general.client_users',
    route: 'client-users',
    icon: 'UserIcon'
  },
  {
    title: 'general.tills',
    route: 'cash_registers',
    icon: 'DatabaseIcon'
  },
  {
    title: 'general.cash_register',
    route: 'cash_register',
    icon: 'DatabaseIcon'
  }
]
