<template>
    <b-modal id="change-password-modal" :title="$t('change_password.title')" no-close-on-backdrop>
        <b-overlay :show="isLoading">
            <validation-observer ref="validation">
                <b-form-group :label="$t('change_password.old_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('change_password.old_password')" rules="required">
                            <b-form-input autofocus name="old_password" type="password" v-model="password_change.old_password" />
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
                <b-form-group :label="$t('change_password.new_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('change_password.new_password')" rules="required" vid="new_password">
                            <b-form-input name="new_password" type="password" v-model="password_change.new_password" />
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
                <b-form-group :label="$t('change_password.repeat_new_password')">
                    <div class="w-full mb-2">
                        <validation-provider #default="{ errors }" :name="$t('change_password.repeat_new_password')" rules="required|confirmed:new_password">
                            <b-form-input name="repeat_new_password" type="password" v-model="password_change.repeat_new_password" data-vv-as="new_password"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </div>
                </b-form-group>
            </validation-observer>
        </b-overlay>
        <template #modal-footer="{ cancel }">
            <b-button variant="danger" @click="cancel()">
                <span class="align-middle">
                    {{$t('general.cancel')}}
                </span>
            </b-button>
            <b-button variant="primary" @click="changePassword">
                {{$t('change_password.change_password')}}
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BModal, BFormInput, BButton, BFormGroup, BOverlay
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import {captureException} from '@sentry/vue'

    export default {
        components: {
            BModal,
            BFormGroup,
            BFormInput,
            BButton,
            BOverlay,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                password_change: {
                    old_password: '',
                    new_password: '',
                    repeat_new_password: ''
                },
                required,
                isLoading: false
            }
        },
        methods: {
            showModal() {
                this.password_change = {
                    old_password: '',
                    new_password: '',
                    repeat_new_password: ''
                }
                this.$bvModal.show('change-password-modal')
            },
            async changePassword() {
                try {

                    this.isLoading = true

                    const valid = await this.$refs.validation.validate()
                    if (!valid) return

                    await this.$http.post('/api/reseller/v1/users/change_password', this.password_change)
                    this.$printSuccess(this.$t('success.on_change_password'))
                    this.$bvModal.hide('change-password-modal')

                } catch (error) {
                    captureException(error)
                    if (error && error.response && error.response.data.error === 'incorrect old password') {
                        this.$printWarning(this.$t('error.on_change_password_old'))
                    } else {
                        this.$printError(this.$t('error.on_change_password'))
                    }
                } finally {
                    this.isLoading = false
                }
            }
        }
    }
</script>

<style scoped>
.w-full{
  width: 100%;
}
</style>

